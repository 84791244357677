import { RedirectLoginOptions } from '@auth0/auth0-react';
import axios from 'axios';
import queryString from 'query-string';

import rollbarLogger from 'config/rollbar';
import { BLACKLIST_ERROR_CODES, BLACKLIST_ERROR_TYPES } from 'config/rollbar/constants/blacklist-error-codes';
import { WHITELIST_URLS_FOR_REQUEST_ERRORS, WHITELIST_URLS_FOR_RESPONSE_ERRORS } from 'config/rollbar/constants/whitelist-urls';
import HttpStatusCode from 'types/http-status-code.enum';

const configAxios = async (
  getAccessTokenSilently: () => Promise<string>,
  loginWithRedirect: (options?: RedirectLoginOptions) => Promise<void>
) => {
  axios.defaults.baseURL = process.env.REACT_APP_API_URL;
  axios.interceptors.request.use(async (config) => {
    const token = await getAccessTokenSilently();
    config.headers.Authorization = `Bearer ${token}`;
    config.paramsSerializer = {
      serialize: (params) => {
        // If the value in params is an object, convert it to a string.
        // See more: https://github.com/sindresorhus/query-string#nesting
        Object.keys(params).forEach((key) => {
          if (typeof params[key] === 'object' && !Array.isArray(params[key])) {
            params[key] = JSON.stringify(params[key]);
          }
        });
        return queryString.stringify(params);
      },
      indexes: null
    };
    return config;
  });

  // A response interceptor for error logging
  axios.interceptors.response.use(
    (response) => response,
    async (error) => {
      if (error.response) {
        if (!WHITELIST_URLS_FOR_RESPONSE_ERRORS.some((url) => url === error.config.url)) {
          const errorDetail = {
            url: `${error.config.baseURL}/${error.config.url}`,
            requestBody: error.config.data,
            token: error.config.headers.Authorization,
            method: error.config.method.toUpperCase(),
            ...error.response.data
          };
          if (error.response.status >= 500) {
            rollbarLogger.error('5xx error from server', errorDetail);
          } else {
            rollbarLogger.warning('4xx error from server', errorDetail);
          }
        }

      } else if (error.request) {
        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
        // http.ClientRequest in node.js
        if (!WHITELIST_URLS_FOR_REQUEST_ERRORS.some((url) => url === error.config.url)) {
          if (!BLACKLIST_ERROR_CODES.some((code) => code === error.code)) {
            rollbarLogger.warning('The request was made but no response was received', { error });
          }
        }
      } else {
        // Something happened in setting up the request that triggered an Error
        if (!BLACKLIST_ERROR_TYPES.some((type) => type === error.error)) {
          rollbarLogger.warning('Something happened in setting up the request that triggered an Error', { error });
        }
      }

      if (error.response?.status === HttpStatusCode.UNAUTHORIZED) {
        // If the token is invalid, we help user re-login
        loginWithRedirect({
          appState: { target: window.location.href }
        });
      }

      return Promise.reject(error);
    }
  );
};

export default configAxios;
